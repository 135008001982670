import { createApp } from 'vue'

import router from './router'
import App from './App.vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import '@/assets/icon/iconfont.css'
import '@/assets/icon/iconfont.js'

import request from '@/service/getData' //全局接口获取

import '@/assets/css/global.scss'
import '@/router/permission'

const app = createApp(App);
app.use(ElementPlus);

app.use(router)
app.mount('#app')

app.config.globalProperties.$request = request;