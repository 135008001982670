import { reactive } from "vue"
const store = reactive({
    rightShowType: "article", // 右侧显示类型
    leftShowType: false, // 左侧显示类型
    generateArticle: false,//一键成文按钮
    loadData: [],//分片上传数据
    reFreshArticle: false,//刷新成文记录列表
    article_id: 0,
    articleRequireData: {
        title: '',
        articleType: 'science',
        wordNums: ''
    },
    allArticleData: //gpt生成文章的数据
        [
            {
                para_cont: 1
            },
            {
                para_cont: 2
            }
        ],
    selectTextData: {
        activeStatus: 'all',//all|some,
        allWords: 0,//字数统计
        materialList: {
            children: [],
        },//选中的素材列表
    },
    nowSubject: {
        id: 0,
        name: "",
        addType: "brainstorming", // brainstorming 头脑风暴  verticalExpansion 垂直拓展 //findPoint 观点
        type: "subject",
        useEngine: 'v1'
    }, // 当前选中的主题
    refreshSubject: false, // 刷新主题
    refreshTreeList: false, // 刷新树形列表
    token: localStorage.getItem("token") || "",
    userInfo: JSON.parse(localStorage.getItem("userInfo") || '{}'),
    lastSelNodeInfo: JSON.parse(localStorage.getItem("lastSelNodeInfo") || '{}'),
    dropContent: {},//正在拖拽的内容
    showRightFileData: {
        show: false
    } //右侧显示的文件数据
})

const setter = {
    setRightShowType: (data) => {
        store.rightShowType = data
    },
    setLeftShowType: (data) => {
        store.leftShowType = data
    },
    setReFreshArticle: (data) => {
        store.reFreshArticle = data
    },
    setArticleRequirementsType: (data) => {
        store.articleRequirementsType = data
    },
    setSelectTextData: (data) => {
        store.selectTextData = data
    },
    setArticleId: (data) => {
        store.article_id = data
    },
    setGenerateArticle: (data) => {
        store.generateArticle = data
    },
    setAllArticleData: (data) => {
        store.allArticleData = data
    },
    setloadData: () => {
        store.loadData = data
    },
    setNowSubject: (data) => {
        store.nowSubject = data
    },
    setUserInfo: (data) => {
        store.userInfo = data
        localStorage.setItem("userInfo", JSON.stringify(data))
    },
    setRefreshSubject: (data) => {
        store.refreshSubject = data
    },
    setRefreshTreeList: (data) => {
        store.refreshTreeList = data
    },
    setToken: (data) => {
        store.token = data
        localStorage.setItem("token", data)
    },
    setLastSelNodeInfo: (data) => {
        store.lastSelNodeInfo = data
        localStorage.setItem("lastSelNodeInfo", JSON.stringify(data))
    },
    setDropContent: (data) => {
        store.dropContent = data
    },
    setShowRightFileData: (data) => {
        store.showRightFileData = data
    }

}


export {
    store,
    setter
}