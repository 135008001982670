import request from '@/service/getData'
import { setter } from '@/store'

export function login() {
    //随机产生一个手机号码并且校验格式
    const phone = getMoble();
    return request.post("/client/login", {
        username: phone,
        loginType: '1',
        code: "888888"
    }, 'json').then(response => {
        if (response.code == 200) {
            setter.setToken(response.data)
        }
    }).catch(err => {
        console.log(err)
    })
}

function getMoble() {
    var prefixArray = new Array("130", "131", "132", "133", "135", "137", "138", "170", "187", "189");
    var i = parseInt(10 * Math.random());
    var prefix = prefixArray[i];
    for (var j = 0; j < 8; j++) {
        prefix = prefix + Math.floor(Math.random() * 10);
    }
    
    return prefix;
}
