<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <router-view />
</template>

<script setup>
import { ref, onMounted } from "vue"
onMounted(() => {
  document.body.style.setProperty("--el-color-primary", "#484FEB");
  document.body.style.setProperty("--el-color-primary-light-3", "#585ff6");
})
</script>

<style>
body {
  margin: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
