import router from './index';
import { store } from '../store/index';
import { login } from '../views/login/login.js';

const whiteList = [
    "/login",
];

router.beforeEach(async (to, from, next) => {
    if (whiteList.indexOf(to.path) !== -1) {
        next();
    } else {
        const token = store.token;
        if (!token) {
            try {
                await login();
                next("/home");
            } catch (err) {
                next(`/login`);
            }
        } else {
            next();
        }
    }
})