import Cookies from 'js-cookie';
import { siteTokenKey } from '@/settings';

//移除本地Cookies
export function removeCookies(key) {
	if(key){
		Cookies.remove(key)
	}else{
		Cookies.remove('user')
		Cookies.remove(siteTokenKey)
	}
}

// localStorage存储
export const SGTorage = function(key, value) {
  let res = null
  if(value === 'remove'){
	if(key){
		localStorage.removeItem(key)
	}else{
		localStorage.clear()
	}
    return;
  }
  if(value) {
	if(value) value = typeof(value)==='string'?value:JSON.stringify(value)
    res = localStorage.setItem(key, value)
  } else {
    res = localStorage.getItem(key) || ''
	if(res.indexOf('{')+res.indexOf('}')>0) res = JSON.parse(res)
  }
  return res
}

// Token存储
export const SGToken = function(value) {
  let res = null
  if(value === 'remove'){
    removeCookies(siteTokenKey)
    return;
  }else if(value) {
    res = Cookies.set(siteTokenKey, value,{ expires: 7 })
  } else {
    res = Cookies.get(siteTokenKey) || ''
  }
  return res
}
// Cookies存储
export const SGCookies = function(key, value) {
  let res = null
  if(value === 'remove'){
	Cookies.remove(key)
    return
  }else if(value) {
	if(value) value = typeof(value)==='string'?value:JSON.stringify(value)
	res = Cookies.set(key, value,{ expires: 7 })
  } else {
	res = Cookies.get(key) || ''
	if(res.indexOf('{')+res.indexOf('}')>0) res = JSON.parse(res)
  }
  return res
}